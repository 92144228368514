<template>
  <dv-full-screen-container class="bj" style="width: 100%; height: 100%">
    <dv-border-box-8 style="background: rgba(22, 40, 80, 0.8)">
      <van-row type="flex" v-if="!login" style="height: 100%; padding: 10px">
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <img
                :src="device?.bandb?.avatar"
                class="logo"
                v-if="device?.bandb?.avatar"
              />
              <img
                src="../assets/slogan.png"
                class="logos"
                v-if="!device.bandb?.avatar"
              />
              <div>卫生联防联控云平台</div>
            </div>
            <div style="display: flex; margin-top: 20px; align-items: flex-end">
              <img :src="staff_info.avatar" alt="" class="logo" />
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                {{ staff_info?.name }}
              </div>
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                【{{ staff_info?.role }}】
              </div>
            </div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtext">芯任净物交接</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div v-if="device.device?.model">
              设备型号：{{ device.device?.model }}
            </div>
            <div v-if="device.device?.id">
              设备编号：{{ device.device?.id }}
            </div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col
          span="24"
          style="
            height: 30%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          "
        >
          <div>
            <img :src="qrcode" alt="" class="qrcode" />
            <div style="font-size: 20px; color: #fff">无人交接</div>
          </div>
          <div>
            <dv-decoration-9
              style="width: 300px; height: 300px; margin: 0 100px; color: #fff"
              >{{ status_msg }}</dv-decoration-9
            >
          </div>
          <div>
            <img class="qrcode" src="../assets/saomiao.png" v-if="thumb" />
            <div style="font-size: 20px; color: #fff">扫描读单</div>
          </div>
        </van-col>
        <van-col span="24" style="height: 40%">
          <div class="preview">
            <div style="font-size: 24px; margin-bottom: 20px">
              <!-- <van-icon name="success" color="#1ED76D" v-if="status" /><van-icon
                name="cross"
                color="red"
                v-if="!status"
              /> -->
            </div>
            <div class="status">
              <div
                class="status_msg"
                v-for="(item, index) in status_msgs"
                :key="index"
              >
                <van-icon
                  name="success"
                  color="#1ED76D"
                  v-if="item.status"
                /><van-icon
                  name="cross"
                  color="red"
                  v-if="!item.status && !item.wran"
                />
                <van-icon
                  name="warn-o"
                  color="yellow"
                  v-if="item.wran && !item.status"
                />{{ item.msg }}{{ item.status ? "成功" : "失败" }}
              </div>
            </div>
          </div>
        </van-col>
        <van-button
          class="button"
          type="success"
          @click="restarts"
          :disabled="status"
          >重启</van-button
        >
      </van-row>
      <van-row v-if="login" type="flex" style="height: 100%; padding: 10px">
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <!-- <img
                :src="data.factory?.avatar"
                class="logo"
                v-if="data.factory?.avatar"
              /> -->
              <img src="../assets/slogan.png" class="logos" />
              <div>卫生联防联控云平台</div>
            </div>
            <div style="display: flex; margin-top: 20px" v-if="staff_info">
              <img :src="staff_info.avatar" alt="" class="logo" />
              <div style="font-size: 20px; color: #fff; line-height: 60px">
                {{ staff_info?.name }}
              </div>
              <div style="font-size: 20px; color: #fff; line-height: 60px">
                【{{ staff_info?.role }}】
              </div>
            </div>
            <div class="infotime">{{ order.time }}</div>
            <div class="infotime" v-if="service">批次号：{{ service }}</div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtitle">芯任净物交接</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div>设备型号：{{ device.device?.model }}</div>
            <div>设备编号：{{ device.device?.id }}</div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col span="8" style="height: 85%">
          <dv-decoration-9 class="zero" :dur="dur" :style="color">
            <countTo
              :startVal="uncount || 0"
              :endVal="msg?.count || 0"
              :duration="3000"
            ></countTo
            >/{{ bucao_count }}</dv-decoration-9
          >
          <div class="dialog_button">
            <dv-border-box-12 class="dialog_button_but" @click="stress"
              >重读</dv-border-box-12
            >
            <dv-border-box-12 class="dialog_button_but" @click="zero"
              >归零</dv-border-box-12
            >
          </div>
        </van-col>
        <van-col span="10" style="height: 85%">
          <dv-border-box-13 class="btn">
            <div class="border" :style="{ height: listheight }">
              <div
                class="app-card-content"
                v-if="bucao_summary && bucao_summary.length < 1"
              >
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">被套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">床单</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">枕套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">浴巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">面巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">地巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
              <div
                class="app-card-content"
                v-if="bucao_summary && bucao_summary.length > 0"
              >
                <div
                  v-for="(item, index) in bucao_summary"
                  :key="index"
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item', getBorderClass(index)]"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box" :style="item.color">
                        {{ item?.match_count ? item?.match_count + "/" : "0/"
                        }}<countTo
                          :startVal="item?.count || 0"
                          :endVal="item?.count || 0"
                          :duration="3000"
                        ></countTo>
                      </div>
                      <div class="box_name">{{ item.name }}</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
              <div
                v-if="msg.unknown_bucao_count > 0"
                style="color: red; font-size: 32px"
              >
                当前未知芯片数量：{{ msg.unknown_bucao_count }}
              </div>
            </div>
          </dv-border-box-13>
        </van-col>
        <van-col span="6" style="height: 85%">
          <dv-border-box-6
            :color="['#3E6BAF', '#30EAF9']"
            style="padding: 10px 0px 0 10px"
          >
            <div class="order_title">订单详情</div>
            <van-collapse v-model="activeNames" accordion class="collapse">
              <van-collapse-item
                :name="index"
                v-for="(item, index) in order"
                :key="index"
              >
                <template #title>
                  <div>
                    <div
                      style="
                        color: #fff;
                        font-size: 16px;
                        text-align: left;
                        line-height: 30px;
                      "
                    >
                      订单号:{{ item.code }}
                    </div>
                    <div
                      style="
                        color: #fff;
                        font-size: 16px;
                        text-align: left;
                        line-height: 30px;
                      "
                    >
                      打包人员:{{ item.staff_name }}
                    </div>
                  </div>
                </template>
                <template #value>
                  <div>
                    <div
                      style="color: #fff; font-size: 16px; line-height: 30px"
                    >
                      {{ item.minute }}
                    </div>
                    <div
                      style="color: #fff; font-size: 16px; line-height: 30px"
                    >
                      数量:{{ item?.count }}
                    </div>
                  </div>
                </template>
                <div
                  v-for="(e, i) in item.bucaos"
                  :key="i"
                  style="
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                  "
                >
                  <div>{{ e.name }}</div>
                  <div>{{ e.count }}</div>
                </div>
                <!-- <div class="order_center" v-if="item.code">
                  <div class="center_title" v-if="item.recv_staff">
                    <div class="title_order">送单详情</div>
                    <div class="title_conter">
                      <div class="title_text">
                        <div>送单人员：</div>
                        <div style="font-size: 12px">
                          {{ item.recv_staff?.name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>送单电话：</div>
                        <div style="font-size: 12px">
                          {{ item?.recv_staff?.phone }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>送单时间：</div>
                        <div style="font-size: 12px">
                          {{ item?.recv_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <dv-decoration-2
                    style="width: 450px; height: 5px"
                    v-if="item.recv_staff"
                  />
                  <div class="center_title" v-if="item?.answer_staff">
                    <div class="title_order">应单详情</div>
                    <div class="title_conter">
                      <div class="title_text">
                        <div>应单人员：</div>
                        <div style="font-size: 12px">
                          {{ item?.answer_staff.name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>应单电话：</div>
                        <div style="font-size: 12px">
                          {{ item?.answer_staff.phone }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>应单时间：</div>
                        <div style="font-size: 12px">
                          {{ item?.answer_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <dv-decoration-2
                    style="width: 450px; height: 5px"
                    v-if="item?.answer_staff"
                  />
                  <div class="center_title" v-if="item?.create_staff">
                    <div class="title_order">下单详情</div>
                    <div class="title_conter">
                      <div class="title_text">
                        <div>下单人员：</div>
                        <div style="font-size: 12px">
                          {{ item?.create_staff?.name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>下单电话：</div>
                        <div style="font-size: 12px">
                          {{ item?.create_staff.phone }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>下单时间：</div>
                        <div style="font-size: 12px">{{ item?.time }}</div>
                      </div>
                      <div class="title_text">
                        <div>下单公司：</div>
                        <div style="font-size: 12px">
                          {{ item?.scene_name }} {{ item?.hotel_name }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>打印情况：</div>
                        <div style="font-size: 12px">
                          {{ item.print_info ? item.print_info : "不打印" }}
                        </div>
                      </div>
                      <div class="title_text">
                        <div>收污地址：</div>
                        <div style="font-size: 12px">
                          {{ item.get_address }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </van-collapse-item>
            </van-collapse>
            <div class="dialog_buttons">
              <dv-border-box-12
                class="dialog_button_buts"
                @click="feedorderaction"
                >确认交接</dv-border-box-12
              >
            </div>
          </dv-border-box-6>
        </van-col>
      </van-row>
    </dv-border-box-8>
  </dv-full-screen-container>
</template>
<script>
import {
  Col,
  Row,
  Tab,
  Tabs,
  Icon,
  List,
  Cell,
  CellGroup,
  PullRefresh,
  Button,
  Card,
  Popup,
  Tag,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Notify,
  Overlay,
  Loading,
  CountDown,
  Circle,
  Toast,
  Progress,
  Checkbox,
  CheckboxGroup,
  Field,
  Dialog,
  Collapse,
  CollapseItem,
} from "vant";
import api from "../utils/api";
import countTo from "./vue-countTo.vue";
import { ref, reactive } from "vue";
import mqtt from "mqtt";
import store from "../store/index";
import _ from "lodash";
export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Card.name]: Card,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Notify.name]: Notify,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [CountDown.name]: CountDown,
    [Circle.name]: Circle,
    [Toast.name]: Toast,
    [Progress.name]: Progress,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    countTo,
  },
  setup() {
    const num = store.state.edition;
    const state = reactive({
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    });
    const onLoad = () => {
      if (state.refreshing) {
        state.list = [];
        state.refreshing = false;
      }
      state.finished = true;
    };
    const onRefresh = () => {
      // 清空列表数据
      state.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      state.loading = true;
    };
    const activeNames = ref("1");
    const duka = ref({
      text: "已断开连接",
      color: "color:red",
    });
    return {
      duka,
      num,
      state,
      onLoad,
      onRefresh,
      activeNames,
      disabled: ref(true),
      listheight: ref(""),
      lineNumber: 3,
      qrcode: ref(""),
      time: 2000,
      backtime: 60000,
      intervalId: ref(null),
      intervalIds: ref(null),
      login_token: ref(""),
      login_data: ref({}),
      login: ref(false),
      mtopic: ref(""),
      mtopic_order: ref(""),
      mtopicheart: ref(""),
      dur: ref(0),
      order: ref({}),
      bucao_summary: ref([]),
      rooms: ref([]),
      scene: ref("hotel"),
      device_id: ref(""),
      device: ref({}),
      thumb: ref(""),
      bucao_count: ref(0),
      read_count: ref(0),
      uncount: ref(0),
      msg: ref({}),
      service: ref(""),
      staff_code: ref(""),
      staff_type: ref(""),
      clientId: ref(""),
      status_msg: ref(""),
      status_msgs: ref([
        {
          msg: "设备获取",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面智慧工作站设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面读卡器设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面票据打印机设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面扫描枪设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "云端连接",
          status: false,
          warn: false,
        },
        {
          msg: "service云端订阅",
          status: false,
          warn: false,
        },
        {
          msg: "order云端订阅",
          status: false,
          warn: false,
        },
      ]),
      networks: ref(false),
      network_time: 5000,
      network_Id: null,
      staff_info: ref({}),
      order_code: ref([]),
      washs: ref({}),
      wash: ref([]),
      color: ref("color:#FFD900"),
      orders: ref([]),
      restartsnum: ref(0),
      restartsts:ref()
    };
  },
  mounted() {
    console.log(this.$route.query);
    document.title = "芯任净物交接";
    this.network();
    this.network_refreh();
    this.listheight = 3.8 + "rem";
    this.staffgetstaffinfo();
    this.devicefetch(this.$route.query.device_code);
    this.clientId =
      this.$route.query.device_code + "_" + Math.floor(Math.random() * 10 + 1);
    console.log(this.clientId);
    if (this.$route.query.order) {
      this.login = true;
      this.clear();
      this.clears();
      this.order_code = this.$route.query.order;
      let order = [];
      order.push(this.$route.query.order);
      this.feedorderdetails(order);
    }
  },
  watch: {
    msg: function (newVal, endVal) {
      console.log(newVal, endVal);
      if (endVal.count && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal.count;
          // if (newVal.records) {
          //   newVal = newVal.records.map((i, e) => {
          //     i.uncount = endVal.records[e].count;
          //   });
          // }
        } else {
          this.uncount == 0;
          // newVal = newVal.records.map((i) => {
          //   i.uncount = 0;
          // });
        }
      }
    },
    // wash: function (newVal, endVal) {},
    read_count: function (newVal, endVal) {
      if (endVal && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal;
        } else {
          this.uncount == 0;
        }
      }
    },
  },
  methods: {
    _isMobile(e) {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (this.flag == null) {
        console.log("==>PC端");
      } else {
        console.log("==>移动端");
        window.Android.playTone(e);
      }
    },
    restart() {
      this.login = false;
    },
    restarts() {
     if (this.restartsts) {
        if (
          new Date().getTime() - this.restartsts < 3000 &&
          this.restartsnum >= 5
        ) {
          Notify({
            message: "请勿频繁操作",
            duration: 3000,
            type: "danger",
          });
          this._isMobile("often_verify");
          this.restartsnum = 0;
        } else {
          this.restartsnum++;
        }
        if (new Date().getTime() - this.restartsts > 3000) {
          this.restartsnum = 0;
        }
      }
      this.restartsts = new Date().getTime();
      this.login = false;
    },
    deskfetchreadingservice() {
      api
        .deskfetchreadingservice({
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
        })
        .then((res) => {
          console.log("首次", res.data);
          if (res.data.errcode == 0 && res.data.data.service) {
            this.service = res.data.data.service;
            this.throttlewash();
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    network() {
      if (window.navigator.onLine == true) {
        let img = new Image();
        img.src =
          "https://fdfs.singran.vip/group1/M00/39/36/rBEAA2DAhiaAcZK4AADaqwfWS6s275.png";
        img.onload = () => {
          console.log("网络已连接");
          // this.status_msg = "网络已连接";
        };
        img.onerror = (e) => {
          console.log("无网络连接，请检查网络", e);
          this.status_msg = "无网络连接，请检查网络";
          if (this.status_msgs) {
            this.status_msgs.map((i) => {
              i.status = false;
              i.warn = false;
            });
          }
          Dialog.alert({
            title: "网络已断开连接",
            message: "请检查网络",
          }).then(() => {});
          this._isMobile("cut_off_internet");
        };
      } else {
        console.log("网络未连接--请检查网络");
      }
    },
    network_refreh() {
      if (this.network_Id) {
        clearInterval(this.network_Id);
      } else {
        console.log(`打开定时器${this.network_time}`);
        this.network_Id = setInterval(() => {
          this.network();
        }, this.network_time);
      }
    },
    mqtt() {
      this.client = mqtt.connect("ws://mqtt.singran.vip:8083/mqtt", {
        clientId: this.clientId,
        clean: true,
        // reconnect: true,
        keepAliveInterval: 30,
        // reconnectInterval: 10,
        username: "xinren_factory",
        password: "xinren20200807",
      });
      // 断开发起重连
      // this.client.on("reconnect", (error) => {
      //   console.log("正在重连:", error);
      //   this.status_msg = "云端尝试重新连接中";
      //   this.status_msgs.push({ msg: "云端尝试重新连接中", status: false });
      // });
      // 链接异常处理
      this.client.on("error", (error) => {
        console.log("连接失败:", error);
        this.status_msg = "云端断开连接";
        Dialog.alert({
          title: "云端断开连接",
          message: "平板未连接上云端，请联系芯任人员",
        }).then(() => {
          // on close
          this.restart();
        });
      });
      this.client.on("connect", (e) => {
        console.log("连接成功", e);
        setTimeout(() => {
          this.status_msg = "云端连接成功";
          this.status_msgs[5].status = true;
        }, 1000);
        this.client.subscribe(this.mtopic, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopic);
            setTimeout(() => {
              this.status_msg = "service云端订阅成功";
              this.status_msgs[6].status = true;
            }, 1200);
          } else {
            setTimeout(() => {
              this.status_msg = "service云端订阅失败";
              this._isMobile("connect_cloud");
            }, 1200);
          }
        });
        this.client.subscribe(this.mtopic_order, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopic_order);
            setTimeout(() => {
              this.status_msg = "order云端订阅成功";
              this.status_msgs[7].status = true;
            }, 1250);
          } else {
            setTimeout(() => {
              this.status_msg = "order云端订阅失败";
              this._isMobile("connect_cloud");
              this.status = false;
            }, 1250);
          }
          setTimeout(() => {
            this.status_msg = "等待读卡中...";
          }, 1300);
        });
        this.client.subscribe(this.mtopicheart, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopicheart);
          }
        });
        this.client.on("message", (topic, message) => {
          // console.log("recv msg ", topic, " => ", String(message));
          this.msg = JSON.parse(String(message));

          if (topic.indexOf("service") != -1) {
            console.log("读卡推送", this.msg);
            if (this.msg.status == 0) {
              console.log("进行中 ===>");
              this.dur = 3;
              this.clears();
              this.read_count = this.msg.count;
              this.service = this.msg.service;
              this.throttlewash();
            } else {
              console.log("已关闭 ===>");
              this.dur = 0;
              this.read_count = 0;
              this.msg = {};
              this.service = "";
              this.bucao_summary.map((i) => {
                i.match_count = 0;
                i.color = "color:#40C5FF";
              });
            }
          } else if (topic.indexOf("order") != -1) {
            console.log("order", this.msg);
            if (this.msg?.type == "feed") {
              console.log("order", this.msg);
              this.clears();
              // this.clear();
              this.login = true;
              if (this.msg.orders) {
                this.order_code = this.msg.orders;
                this.deskfetchreadingservice();
                this.feedorderdetails(this.msg.orders);
              } else if (this.msg.order) {
                this.clear();
                this.orders.push(this.msg.order);
                this.orders = _.uniq(this.orders);
                console.log("this.orders", this.orders);
                this.order_code = this.orders;
                this.deskfetchreadingservice();
                this.feedorderdetails(this.orders);
              }
            }
            // this.backRefrehs()
          }
          if (topic.indexOf("heart") != -1) {
            console.log("心跳", this.msg);
            if (this.msg.connect == 0) {
              this.duka = {
                text: "已断开连接",
                color: "color:red",
              };
              Dialog.alert({
                title: "读卡设备已断开网络，请检查网络",
                message: "请检查网络",
              }).then(() => {});
            } else {
              this.duka = {
                text: "网络已连接",
                color: "color:green",
              };
            }
          }
        });
      });
    },
    readerstatus() {
      api
        .readerstatus({
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
        })
        .then((res) => {
          console.log("首次心跳", res);
          if (res.data.data.status == 1) {
            this.duka = {
              text: "网络已连接",
              color: "color:green",
            };
          } else {
            this.duka = {
              text: "已断开连接",
              color: "color:red",
            };
            Dialog.alert({
              title: "读卡设备已断开网络，请检查网络",
              message: "请检查网络",
            }).then(() => {});
          }
        });
    },
    feeddifforderfeed() {
      api
        .feeddifforderfeed({
          orders: this.order_code,
          service: this.service,
        })
        .then((res) => {
          console.log("feeddiff", res.data);
          if (res.data.errcode == 0) {
            this.washs = res.data.data;
            this.wash = res.data.data.summary;
            if (res.data.data.read_count > res.data.data.order_code) {
              this.color = "color:red";
            }
            this.bucao_summary = this.wash.map((i) => {
              if (i.read_count > i.match_count) {
                return {
                  code: i.code,
                  name: i.name,
                  sort: i.sort,
                  count: i.order_count,
                  match_count: i.match_count,
                  read_count: i.read_count,
                  color: "color:red",
                };
              } else {
                return {
                  code: i.code,
                  name: i.name,
                  sort: i.sort,
                  count: i.order_count,
                  match_count: i.match_count,
                  read_count: i.read_count,
                  color: "color:#40C5FF",
                };
              }
            });
            console.log("this.bucao_summary", this.bucao_summary);
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    washdifforderwash() {
      api
        .washdifforderwash({
          orders: this.order_code,
          service: this.service,
        })
        .then((res) => {
          console.log("wash", res.data);
          if (res.data.errcode == 0) {
            this.washs = res.data.data;
            this.wash = res.data.data.summary;
            if (res.data.data.read_count > res.data.data.order_code) {
              this.color = "color:red";
            }
            this.bucao_summary = this.wash.map((i) => {
              if (i.read_count > i.match_count) {
                return {
                  code: i.code,
                  name: i.name,
                  sort: i.sort,
                  count: i.order_count,
                  match_count: i.match_count,
                  read_count: i.read_count,
                  color: "color:red",
                };
              } else {
                return {
                  code: i.code,
                  name: i.name,
                  sort: i.sort,
                  count: i.order_count,
                  match_count: i.match_count,
                  read_count: i.read_count,
                  color: "color:#40C5FF",
                };
              }
            });
            console.log("this.bucao_summary", this.bucao_summary);
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    throttlewash: _.throttle(function () {
      this.feeddifforderfeed();
    }, 500),
    staffgetstaffinfo(e) {
      api
        .staffgetstaffinfo({
          staff_code: e || this.$route.query.staff_code,
          staff_type: e ? "factory" : "bandb",
        })
        .then((res) => {
          console.log("staff", res.data);
          if (res.data.errcode == 0 && !e) {
            res.data.data.staff_info.avatar =
              "http://api.singran.vip/staffavatar/" +
              res.data.data.staff_info.code;
            this.staff_info = res.data.data.staff_info;
            if (res.data.data.staff_info.role_code == "bandb_manager") {
              res.data.data.staff_info.role = "总经理";
            } else if (res.data.data.staff_info.role_code == "bandb_cleaner") {
              res.data.data.staff_info.role = "保洁员";
            } else if (res.data.data.staff_info.role_code == "bandb_poster") {
              res.data.data.staff_info.role = "驿站管理员";
            }
          } else {
            res.data.data.staff_info.avatar =
              "http://api.singran.vip/staffavatar/" +
              res.data.data.staff_info.code;
            this.staff_info = res.data.data.staff_info;
            if (res.data.data.staff_info.role_code == "factory_manager") {
              res.data.data.staff_info.role = "总经理/厂长";
            } else if (res.data.data.staff_info.role_code == "factory_ws") {
              res.data.data.staff_info.role = "生产管理";
            } else if (
              res.data.data.staff_info.role_code == "factory_customer"
            ) {
              res.data.data.staff_info.role = "工厂客户";
            } else if (res.data.data.staff_info.role_code == "factory_feeder") {
              res.data.data.staff_info.role = "布草管理";
            } else if (
              res.data.data.staff_info.role_code == "factory_deliver"
            ) {
              res.data.data.staff_info.role = "配送员";
            } else if (
              res.data.data.staff_info.role_code == "factory_postation"
            ) {
              res.data.data.staff_info.role = "驿站管理员";
            }
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    feedorderaction() {
      if (this.washs.order_count != this.washs.match_count) {
        Dialog.confirm({
          title: "请确认",
          message: "当前读取数量和订单数量有差异，请确认是否下单？",
        })
          .then(() => {
            // on confirm
            console.log("1234", this.staff_type);
            const ordernos = this.order.map((i) => {
              return i.code;
            });
            api
              .feedorderaction({
                service: this.service,
                orders: ordernos,
                staff_code: this.$route.query.staff_code,
                staff_type: "bandb",
                action: "verify",
                scene: "bandb",
                app_code: this.$route.query.app_id,
                app_scene: this.$route.query.app_scene,
              })
              .then((res) => {
                console.log("确认下单", res);
                if (res.data.errcode != 0) {
                  Notify({
                    message: res.data.errmsg,
                    duration: 3000,
                    type: "danger",
                  });
                  return;
                }
                Toast.success({
                  message: "操作成功！",
                });
                setTimeout(() => {
                  this.restart();
                }, 1000);
              });
          })
          .catch(() => {});
      } else {
        const ordernos = this.order.map((i) => {
          return i.code;
        });
        api
          .feedorderaction({
            service: this.service,
            orders: ordernos,
            staff_code: this.$route.query.staff_code,
            staff_type: "bandb",
            action: "verify",
            scene: "bandb",
            app_code: this.$route.query.app_id,
            app_scene: this.$route.query.app_scene,
          })
          .then((res) => {
            console.log("确认下单", res);
            if (res.data.errcode != 0) {
              Notify({
                message: res.data.errmsg,
                duration: 3000,
                type: "danger",
              });
              return;
            }
            Toast.success({
              message: "操作成功！",
            });
            setTimeout(() => {
              this.restart();
            }, 1000);
          });
      }
      // if (this.washs.order_count != this.washs.match_count) {
      //   Notify({
      //     message: "当前批次数据有误，请关闭重新读取",
      //     duration: 3000,
      //     type: "danger",
      //   });
      //   return;
      // }
    },
    // washorderaction() {
    //   console.log(this.washs.order_count, this.washs.match_count);
    //   if (this.washs.order_count != this.washs.match_count) {
    //     Notify({
    //       message: "当前批次数据有误，请关闭重新读取",
    //       duration: 3000,
    //       type: "danger",
    //     });
    //     return;
    //   }
    //   const ordernos = this.order.map((i) => {
    //     return i.code;
    //   });
    //   api
    //     .washorderaction({
    //       service: this.service,
    //       orders: ordernos,
    //       staff_code: this.$route.query.staff_code,
    //       action: "verify",
    //       app_code: this.$route.query.app_id,
    //       app_scene: this.$route.query.app_scene,
    //     })
    //     .then((res) => {
    //       console.log("确认下单", res);
    //       if (res.data.errcode != 0) {
    //         Notify({
    //           message: res.data.errmsg,
    //           duration: 3000,
    //           type: "danger",
    //         });
    //         return;
    //       }
    //       Toast.success({
    //         message: "操作成功！",
    //       });
    //       // this.backRefrehs();
    //       setTimeout(() => {
    //         this.restart();
    //       }, 1000);
    //     });
    // },
    debouncewash: _.debounce(function () {
      this.feedorderaction();
    }, 3000),
    stress() {
      console.log("重读");
      this.clears();
      this.msg = {};
      this.uncount = 0;
      this.deskservice("1");
      setTimeout(() => {
        this.restart();
      }, 1000);
    },
    zero() {
      this.clears();
      this.msg = {};
      this.uncount = 0;
      // this.order = {};
      // this.rooms = {};
      this.deskservice("1");
    },
    deskservice(e) {
      console.log(this.service);
      this.clears();
      api
        .servicecontrol({ code: this.service, status: e, device_type: "desk" })
        .then((res) => {
          console.log("操作", e, this.service, res);
          if (res.data.errcode == 0 && e == 1) {
            Notify({ message: "已关闭", duration: 1000, type: "danger" });
          } else if (res.data.errcode == 0 && e == 2) {
            Notify({ message: "已暂停", duration: 1000, type: "warning" });
          } else if (res.data.errcode == 0 && e == 3) {
            Notify({ message: "已继续", duration: 1000, type: "success" });
          }
        });
    },
    devicefetch(e) {
      api
        .devicefetch({
          device_code: e,
        })
        .then((res) => {
          console.log("设备", res.data);
          if (res.data.errcode != 0) {
            Dialog.alert({
              title: res.data.errmsg,
              message: "请检查平板归属与设备类型",
            }).then(() => {
              // on confirm
            });
            this.status_msg = "设备获取失败";
            this.status_msgs[0].status = false;
            return;
          }
          this.status_msg = "设备获取成功";
          this.status_msgs[0].status = true;
          if (res.data.data.device.category == "dpod") {
            setTimeout(() => {
              if (res.data.data.device.code) {
                this.status_msg = res.data.data.device.name + "设备配置成功";
                this.status_msgs[1].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.device.lctf) {
                this.status_msg =
                  res.data.data.device.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.device.prnt) {
                this.status_msg =
                  res.data.data.device.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status = false;
                return;
              }
            }, 600);
            setTimeout(() => {
              if (res.data.data.device.bcsa) {
                this.status_msg =
                  res.data.data.device.bcsa_name + "设备配置成功";
                this.status_msgs[4].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[4].msg + "失败";
                this.status = false;
                return;
              }
            }, 800);
          } else if (res.data.data.device.category == "lctf") {
            setTimeout(() => {
              if (res.data.data.dpod.code) {
                this.status_msg = res.data.data.dpod.name + "设备配置成功";
                this.status_msgs[1].status = true;

                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.dpod.lctf) {
                this.status_msg = res.data.data.dpod.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.dpod.prnt) {
                this.status_msg = res.data.data.dpod.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status = false;
                return;
              }
            }, 600);
            setTimeout(() => {
              if (res.data.data.dpod.bcsa) {
                this.status_msg = res.data.data.dpod.bcsa_name + "设备配置成功";
                this.status_msgs[4].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[4].msg + "失败";
                this.status = false;
                return;
              }
            }, 800);
          }
          this.device = res.data.data;
          this.device_id = res.data.data.device.id;
          this.thumb = res.data.data.equipment.thumb;
          this.readerstatus();
          this.mtopic =
            "device/" +
            (res.data.data.device.lctf || res.data.data.dpod.lctf) +
            "/service";
          this.mtopic_order =
            "device/" +
            (res.data.data.device.lctf || res.data.data.dpod.lctf) +
            "/order";
          this.mtopicheart =
            "device/" +
            (res.data.data.device.lctf || res.data.data.dpod.lctf) +
            "/heart";
          this.mqtt();
          this.deviceqrauth();
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    feedorderdetails(e) {
      api.feedorderdetails({ orders: e }).then((res) => {
        console.log("feedorder", res.data);
        if (res.data.errcode != 0) {
          Notify({
            message: res.data.errmsg,
            duration: 1000,
          });
          return;
        }
        res.data.data.orders.map((i) => {
          i.minute = i.minute.split(" ")[1];
        });
        this.order = res.data.data.orders;
        this.bucao_count = res.data.data.bucao_count;
        this.bucao_summary = res.data.data.bucaos;
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    washorderdetails(e) {
      api.washorderdetails({ orders: e }).then((res) => {
        console.log("order", res.data);
        if (res.data.errcode != 0) {
          Notify({
            message: res.data.errmsg,
            duration: 1000,
          });
          return;
        }
        res.data.data.orders.map((i) => {
          if (i.scene == "bandb") {
            if (!i.print_count) {
              i.print_info = "未打印";
            } else if (i.print_count < 0) {
              i.print_info = "重打印";
            } else {
              i.print_info = i.time;
            }
          } else {
            i.print_info = "";
          }
          i.minute = i.minute.split(" ")[1];
        });
        this.order = res.data.data.orders;
        this.bucao_count = res.data.data.bucao_count;
        this.bucao_summary = res.data.data.bucao_summary;
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    deviceqrauth() {
      api.deviceqrauth({ action: "open" }).then((res) => {
        this.qrcode =
          "https://api.singran.vip/qrcode/singran/" +
          encodeURIComponent(
            `https://qr.singran.vip/feed/order/hand/${res.data.login_token}/${this.device_id}`
          );
        console.log(res.data, this.qrcode, this.device_id);
        if (res.data.errcode == 0) {
          this.login_token = res.data.login_token;
          this.backRefreh();
        }
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    check() {
      api
        .deviceqrauth({
          action: "check",
          login_token: this.login_token,
        })
        .then((res) => {
          console.log("登录", res.data);
          this.login_data = res.data;
          if (res.data.errcode == 100) {
            console.log("还没有用户扫码订单编辑二维码");
            return;
          }
          if (res.data.errcode == 200) {
            console.log("用户已经扫码登录");
            this.staff_code =
              res.data?.staff.code || this.$route.query.staff_code;
            this.staff_type = res.data?.staff.type || "bandb";
            this.staffgetstaffinfo(res.data?.staff.code);
            this.clear();
            // this.login = !this.login;
            return;
          }
          if (res.data.errcode == 401) {
            console.log("401刷新页面");
            this.restart();
            return;
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    backRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId) {
        clearInterval(this.intervalId);
      } else {
        console.log(`打开定时器${this.time}ms `);
        this.intervalId = setInterval(() => {
          console.log("check");
          this.check();
        }, this.time);
      }
    },
    backRefrehs() {
      if (this.intervalIds) {
        clearInterval(this.intervalIds);
      } else {
        console.log(`打开定时器${this.backtime}ms `);
        this.intervalIds = setInterval(() => {
          this.login = !this.login;
          this.restart();
        }, this.backtime);
      }
    },
    // 停止定时器
    clear() {
      console.log(`关闭定时器`);
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    clears() {
      console.log(`关闭定时器`);
      clearInterval(this.intervalIds); //清除计时器
      this.intervalIds = null; //设置为null
    },
    getBorderClass(index) {
      index += 1;
      let className = "";
      let lineNumber = this.lineNumber; // 每行行显示个数
      let total = this.bucao_summary.length; // 总个数
      let line = Math.ceil(total / lineNumber); // 一共有几行
      let currentLine = 0; // 当前在第几行,这里从1开始
      let currentIndex = 0; // 当前在某行的第几个
      currentLine = Math.ceil(index / lineNumber);
      currentIndex = index % lineNumber === 0 ? lineNumber : index % lineNumber;
      // 右下边框
      if (currentLine < line && currentIndex < lineNumber) {
        className = "border-bottom border-right";
      }
      // 右边框
      if (currentLine === line && currentIndex < lineNumber) {
        className = "border-right";
      }
      // 下边框
      if (currentLine < line && currentIndex === lineNumber) {
        className = "border-bottom";
      }
      // 无边框
      if (currentLine === line && currentIndex === lineNumber) {
        className = "";
      }
      return className;
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.van-collapse-item__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell {
  background: rgba(0, 0, 0, 0);
  padding: 3px;
}
/deep/.van-cell__title,
.van-cell__value {
  flex: none;
}
/deep/.van-popup {
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell-group {
  background: rgba(0, 0, 0, 0);
}
.bj {
  background-image: url("../assets/bj.jpg");
}
.logo {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 2px;
  margin-right: 3px;
}
.logos {
  width: 20px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
}
.info {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  height: 10px;
  margin-bottom: 2px;
}
.infotime {
  font-size: 12px;
  color: #fff;
  text-align: left;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.onheard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.heard {
  height: 16px;
  width: 16px;
}
.device {
  color: #fff;
  font-size: 10px;
  width: 25%;
  text-align: right;
  margin-right: 10px;
}
.border {
  border-radius: 5px;
  padding: 3px;
}
.app-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(12, 24, 51, 0);
}
.app-card-content_item {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 4px;
}
.fenlei {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  font-size: 30px;
  color: #40c5ff;
}
.box_name {
  font-size: 18px;
  color: #4a9cbd;
}
.btn {
  padding-top: 5px;
}
.qrcode {
  width: 150px;
  height: 150px;
  border: 1px solid black;
}
.heardtext {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.zero {
  width: 200px;
  height: 200px;
  margin: 50px auto;
  font-size: 50px;
  color: #ffd900;
}
.dialog_button {
  display: flex;
  justify-content: space-around;
}
.dialog_buttons {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 40px;
  width: 25%;
  height: 15%;
}
.dialog_button_but {
  height: 50px;
  margin-top: 5px;
  width: 45%;
  font-size: 20px;
  line-height: 50px;
  color: #40c5ff;
}
.dialog_button_buts {
  height: 40px;
  margin-top: 45px;
  width: 45%;
  font-size: 16px;
  line-height: 40px;
  color: #40c5ff;
}
.order_title {
  font-size: 16px;
  color: #3fc0fa;
}
.rooms_img {
  width: 70px;
  height: 45px;
  margin-left: 10px;
}
.order_center {
  color: #fff;
  font-size: 10px;
  overflow-y: scroll;
}
.order_center::-webkit-scrollbar {
  display: none;
}
.center_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 1px 0px 1px;
  width: 100%;
}
.title_order {
  font-size: 9px;
  color: #3fc0fa;
}
.title_conter {
  padding: 1px 5px;
  width: 100%;
  font-size: 7px;
}
.title_text {
  margin: 1px 0;
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.overflow {
  overflow-y: scroll;
}
.overflow::-webkit-scrollbar {
  display: none;
}
.preview {
  font-size: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.status_msg {
  border-top: 1px solid rgb(255, 255, 255);
  padding: 2px;
  text-align: left;
}
.status {
  width: 20%;
  margin: 10px auto;
  height: 150px;
  overflow-y: scroll;
}
.status::-webkit-scrollbar {
  display: none;
}
.collapse {
  overflow-y: scroll;
  height: 300px;
}
.collapse::-webkit-scrollbar {
  display: none;
}
.button {
  width: 80px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.duka {
  font-size: 8px;
}
</style>