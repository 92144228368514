<template>
  <Deskhand />
</template>

<script>
import Deskhand from "../../../components/factory_deskhand";

export default {
  name: "factory_deskhand",
  components: {
    Deskhand,
  },
};
</script>